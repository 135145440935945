import styled from "styled-components"

import { EditField, TEditFieldSave } from "src/components/Settings/EditField"
import { IToggleSection } from "src/data/editField/editFieldTypes"
import { getStoredValue } from "src/data/editField/logic/editFieldLogic"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { spacing } from "src/ui/spacing"

export function ToggleSection<S, P>({
  subSection,
  onSave,
  loading,
  storedSettings,
}: {
  subSection: IToggleSection<S, P>
  onSave: TEditFieldSave<S, P>
  storedSettings: S[]
  loading: boolean
}) {
  const featureAvailability = useFeatureAvailability({
    feature: subSection.toggle.feature || Feature.EDITFIELD_ALLOW,
  })
  const isBulkEdit = storedSettings.length > 1

  function isExpanded() {
    if (!featureAvailability.available) {
      return false // don't show sub-settings if plan doesn't allow editing setting
    }
    if (isBulkEdit) {
      return true // always expand during bulk edit (if plan allows toggling)
    }
    const storedValue = getStoredValue(
      subSection.toggle,
      storedSettings,
      !featureAvailability.available
    )
    return storedValue === subSection.toggle.trueVal
  }

  return (
    <div>
      <EditField
        storedSettings={storedSettings}
        loading={loading || !featureAvailability.ready}
        fieldData={subSection.toggle}
        submit={onSave}
      />
      {isExpanded() && (
        <IndentedBox>
          {subSection.fields.map((setting) => {
            return (
              <EditField
                storedSettings={storedSettings}
                loading={loading || !featureAvailability.ready}
                fieldData={setting}
                submit={onSave}
                key={setting.id}
              />
            )
          })}
        </IndentedBox>
      )}
    </div>
  )
}

const IndentedBox = styled.div`
  margin-left: ${spacing.M};
`
